import React from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';

const mock = [
  {
    name: 'Type of School',
    email: 'Coeducational',
    
  },
  {
    name: 'Board',
    email: 'CBSE',
    
  },
  {
    name: '	Year levels offered in ',
    email: '',
  
  },
  {
    name: '2013-14',
    email: 'Montessori – I, Kindergarten I & II, Classes 1-8',
  
  },
  {
    name: '2014-15',
    email: 'Montessori – I,II, Kindergarten I & II, Classes 1-9',
  
  },
  {
    name: '2015-16',
    email: 'Montessori – I,II,III, Kindergarten I & II, Classes 1-11',
  
  },
  {
    name: '2016-17',
    email: 'Montessori – I,II,III,  Kindergarten I & II, Classes 1-12',
    
  },
  {
    name: '2017-18',
    email: 'Montessori – I,II,III,  Kindergarten I & II, Classes 1-12',
  
  },
  {
    name: '2018-19',
    email: 'Montessori – I,II,III,  Kindergarten I & II, Classes 1-12',
  
  },
  {
    name: '2019-20',
    email: 'Montessori – I,II,III,  Kindergarten I & II, Classes 1-12',
    
  },
  {
    name: '2020-21',
    email: 'Montessori – I,II,III,  Kindergarten I & II, Classes 1-12',
  
  },
  {
    name: '2021-22',
    email: 'Montessori – I,II,III,  Pre-K, Kindergarten I & II, Classes 1-12',
  
  },

  {
    name: '2022-23',
    email: 'Montessori – I,II,III,  Pre-K, Kindergarten I & II, Classes 1-12',
  
  },
  {
    name: '2023-24',
    email: 'Montessori – I,II,III, Pre-K, Kindergarten I & II, Classes 1-12',
  
  },
  {
    name: '2024-25',
    email: 'Montessori – I,II,III, Pre-K, Kindergarten I & II, Classes 1-12',
  
  },
  {
    name: '2025-26',
    email: 'Montessori – I,II,III, Pre-K, Kindergarten I & II, Classes 1-12',
  
  },
  
];

const table = () => {
  const theme = useTheme();
  return (
    
    
    <Container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: 'alternate.dark' }}>
            <TableRow>
              <TableCell>
                {/* <Typography
                  variant={'caption'}
                  fontWeight={700}
                  align='right'
                  sx={{ textTransform: 'uppercase' }}
                >
                  School Profile
                </Typography> */}
              </TableCell>   
              <TableCell align='center'>
                <Typography
                  variant={'caption'}
                  fontWeight={700}
                  
                  sx={{ textTransform: 'uppercase' }}
                >
                   School Profile
                </Typography>
              </TableCell> 
              <TableCell>
                {/* <Typography
                  variant={'caption'}
                  fontWeight={700}
                  sx={{ textTransform: 'uppercase' }}
                >
                  Role
                </Typography> */}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {mock.map((item, i) => (
              <TableRow
                key={i}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:nth-of-type(2n)': { bgcolor: 'alternate.main' },
                }}
              >
                <TableCell component="th" scope="row">
                  <Typography variant={'subtitle2'} fontWeight={700}>
                    {item.name}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'text.secondary'} variant={'subtitle2'}>
                    {item.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'text.secondary'} variant={'subtitle2'}>
                    {item.title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant={'caption'}
                    fontWeight={700}
                    sx={{ color: theme.palette.success.dark }}
                  >
                    {item.status}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography color={'text.secondary'} variant={'subtitle2'}>
                    {item.role}
                  </Typography>
                </TableCell>
              
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default table;