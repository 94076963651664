/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@emotion/react';
// import p1 from 'assests/Photos/knowmore/History/history.png';


const Form = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box>
        <Typography
          variant="h4"
          align = 'center'
          sx={{
            fontWeight: 700,
          }}
        >
          History
        </Typography>
        <br />
      < br/>
        <Typography variant='h5' fontWeight={900} align='center'>
        Our Parent School – National Public School Rajajinagar<br/>
        </Typography>
        <Typography  component={'p'} align='justify'>
        <br/>National Public School started its journey in the year 1959 under the pioneering effort and careful nurturing by our Founder Chairman of NPS Group of Institutions Dr. K P Gopalkrishna and Senior Principal Dr. Santhamma Gopalkrishna. NPS has been known for its academic excellence, discipline and ethical practices which stands tall and high even to this day in the field of education. The group of schools is the result of the foresight and a noble effort of nation-building. NPS school community stands committed to transform learners into responsible citizens by developing them through the modern work culture of the present world and instilling the moral and ethical values in the roots of their heart and mind.
        <br/>Drawing on the legacy of the NPS Group of Institutions and the innovative, immersive methods of school systems around the world, our schools were instituted with one aim – to teach children not just what to learn, but how to learn. 

        </Typography>
        <Typography variant='h5' fontWeight={900} align='center'>
        <br/>National Public School Yeshwanthpur At A Glance <br/>
        </Typography>
        <Typography variant='italic' component={'p'} align='center' fontWeight={700} fontStyle ='italic'>
        <br/>Excellence in education and fostering the love of learning is the hallmark of <br/> “National Public School Yeshwanthpur”<br/>
        </Typography>
        <Typography  component={'p'} align='justify'>
         <br/>Established in the year 2013-14, the school has grown into a renowned institution trusted for its principles on discipline, quality and holistic education. A heart filled with aspirations is all that was known since the very existence of the school and that's what gave the ambition of marching forward till excellence is achieved in all the endeavours. Over the years, the school has fashioned well-rounded students who have flourished with successful grades and credits. The school has provided equal opportunities and support for the student community to develop their interests in sports and other skill-based activities.
         <br/>The goal of the institution is to provide quality teaching and learning environments for our students. The school collectively has an enthusiastic and dedicated team of 125 teachers in the Senior Secondary, Secondary, the Primary and the Pre-Primary sections. In addition, we have Non-Teaching staff and Supporting staff. Their coordination with each other is what enables the school to function smoothly and grow with new perspectives. 
         <br/>Innovation is the change that unlocks new value which leads to success. The school continues to strive towards innovation and for that the latest practices in the field of education are being continuously incorporated. 
        </Typography>
      </Box>
    </Box>
  );
};

export default Form;
